@import '../../styles/variables';
@import '../../styles/mixins';

.settings-container {
  display: flex;
  position: relative;

  .loading-holder {
    top: $header-height;
  }
}

.settings-body {
  padding: 26px;
  width: 100%;
  overflow: scroll;

  .page-content {
    padding: 0;
    margin: 0;
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 30px;
    color: #070F29;
  }
}

.filter-container {
  max-width: 750px;
  width: 100%;
  .btn-user-disabled{
    margin-right: 14px;
    height: 60px;
  }
  .btn-add-user{
    height: 60px;
  }
}

.settings-tabs__container {
  display: flex;
  flex-direction: column;
  width: 180px;
  background-color: #DADEE4;

  .company-admin-menu,
  .global-admin-menu,
  .agent-admin-menu,
  .settings-tabs {
    @include reset-list();

    br {
      display: none;
    }

    a {
      position: relative;
      display: block;
      width: 100%;
      padding: 13px 20px;
      color: #33333B;

      &:hover,
      &.active {
        text-decoration: none;
        background: #CBD0D7;
      }

      &.active {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          display: block;
          height: 100%;
          width: 6px;
          background: #3DB1E6;
        }
      }
    }
  }
}

.global-filter-field-cnt {
  padding: 30px;
  &.flights-container{
    padding: 13px !important;
  }

  &__title {
    margin-bottom: 36px;
    font-weight: 700;
    font-size: 20px;
    color: #070F29;
  }

  & + .global-filter-field-cnt {
    border-top: 1px solid #E4E6E9;
  }
}
.filter-card{
    //height: 380px;
    //width:520px;
    background: white;
    border-radius: 15px;
    margin: 15px 25px 25px;
    box-shadow: 0 12px 44px rgba(22,71,145,0.16);
    &.subs-card{
      width: 480px !important;
      //height:570px !important;
    }
    &.notification-card{
      padding: 13px 0 19px 13px;
      //width: 384px;
      //height:554px;
      .cust-cb {
        label{
          font-family: 'Open Sans', sans-serif;
        }
      }
      .cust-cb input:not(checked) + label:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border: 1px solid #d8dadf;
    border-radius: 4px;
}
.cust-cb input:checked + label:before {
  border-color: #007680;
}
      .global-filter-field-cnt__title {
        font-family: 'Open Sans', sans-serif;
        font-weight: $fw-bold;
        margin-bottom: 10px;
      }
      .radio-cnt{
        margin: 0px;
      }
      .radio-cnt .form-group .cust-rb + .cust-rb {
    margin: 4px 0px 0px 0px;
}
    .checkbox-group + .checkbox-group {
    margin: 0;
    }
      label{
        font-family: 'Open Sans',sans-serif;
        font-size: 16px;
        color:#33333B;
        pointer-events: none;
      }
    input:not(checked) + label:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 1px solid #007680;;
    border-radius: 1px;
    }
    .radio-cnt{
      input:not(checked) + label:before {
        border-radius: 50%;
        border-color: #AAAAAA;
      }
      input:not(checked) + label:after {
    top: 5px;
    left: 6px;
    width: 10px;
    height: 10px;
    }
    }
    .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 0;
    }
    }
}
// .grid-container{
//   display: flex;
//   flex-wrap: wrap;
//   margin-left: 40px;
//   &.grid-align{
//   margin-left: 14px !important;
//   }
//   .grid-subcnt{
//     flex-direction: column;
//     .filter-card{
//     &:first-child(){
//       height:270px !important;
//       padding: 15px !important;
//       margin-bottom: 50px !important;
//     }
//     &:nth-child(2){
//       height: 280px !important;
//       padding: 5px 11px !important;
//       .select-cnt{
//         margin: 10px 0 15px 0px !important;
//       }
//     }
//     }
//   }
// }
.checkbox-group {
  display: flex;
  justify-content: flex-start;

  &_cols {
    flex-direction: column;
  }

  .label-cnt {
    width: 140px;
    h4 {
      margin: 0;
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      color: #070F29;
    }
  }

  .cust-cb {
    div {
      position: relative;
      & + div {
        margin-top: 10px;
      }
    }
    .child-label{
      input:not(checked) + label:before {
        top: -2px;
        left: -10px;
      }
      label:after {
        top: -2px;
        left: 28px;
      }
    }
    &.color-coding-label{
      font-size: 16px  !important;
      color: #33333B !important;
      label{
        padding: 0 0 0 74px !important;
        pointer-events:none;
      }
    }
    &.only-vip-label{
      font-size: 16px;
      color: #33333B !important;
    }
  }

  & + .checkbox-group {
    margin-top: 30px;
  }

  .sub-checkbox-group {
    padding-left: 30px;
    .child-label{
      margin: -10px 18px;
      label {
        position: relative;
        padding: 0px 0 0 65px !important;
      }
    }
  }
}

.radio-cnt {
  margin: 8px 0;
  padding-left: 35px;

  .form-group {
    display: flex;
    flex-direction: column;

    .cust-rb + .cust-rb {
      margin-top: 8px;
    }
  }
}

.select-cnt {
  display: flex;
  align-items: center;
  margin-top: 30px;

  &__label {
    margin-right: 20px;
    font-weight: 700;
    font-size: 16px;
    color: #070F29;
  }
  .Select-menu-outer{
    width: 140px;
    position: absolute;
    left: 6px;
  }

  .Select-control, .Select-input>input {
    width: 120px;
  }

  & + .checkbox-group {
    margin-top: 30px;
  }
  .Select-control{
    border-color: #002D72;
    width: 140px;
    height: 34px;
  }
}

.btn-cnt {
  padding: 10px 50px 30px 50px;
  display: flex;
  justify-content: flex-start;
  &.btn-group-align{
    padding: 10px 50px 30px 24px;
  }
  &.btn-filter-align{
    padding: 10px 50px 30px 43px !important;
  }
  &.btn-settings-align{
    //padding: 80px 50px 30px 25px !important;
    .btn-user-disabled,.btn-add-user{
      height: 60px !important;
    }
  }
  .btn {
    background: none;
    border: none;
    border-radius: $small-border-radius;
    padding: 8px 28px;

    &:hover {
      opacity: .8;
    }

    &_transparent {
      border: 1px solid #C9CED6;

      &[disabled] {
        color: #C9CED6;
        opacity: .8;
      }
    }

    &_blue {
      background-color: #87CCEC;

      &[disabled] {
        background: #C9CED6;
        opacity: .8;
      }
    }

    & + .btn {
      margin-left: 15px;
    }
  }
  .btn-user-disabled{
    background: #DFDFDF !important;
    border-radius: 13px !important;
    border: 1px solid #dde3ed !important;
    padding: 9px!important;
    color: #595B60 !important;
  }
  .btn-add-user{
    border-radius: 12px !important;
    background: #E0E1E3 !important;
  }
}

.airport-within {
  label {
    position: relative;
    padding: 0px 0 0 37px !important;

    &:after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      position: absolute;
      left: 26px;
      top: 6px;
      border-radius: 3px;
    }

    &[for="blackAirportStatus"] {
      &:after {
        background: black;
      }
    }

    &[for="redAirportStatus"] {
      &:after {
        background-color: #F25050;
      }
    }

    &[for="yellowAirportStatus"] {
      &:after {
        background-color: #FFB803;
      }
    }

    &[for="greenAirportStatus"] {
      &:after {
        background-color: #3DBC56;
      }
    }
    &[for="flightStatusChangeAll"] {
      &:after {
        background-color: #002D72;
      }
    }
    &[for="flightStatusChangeEarly"],&[for="flightStatusChangeOntime"], &[for="flightStatusChangeDelayGreen"]{
      &:after {
        background-color: #4BC07A;
      }
    }
    &[for="flightStatusChangeAllDelayYellow"] {
      &:after {
        background-color: #FFB803;
      }
    }
    &[for="flightStatusChangeAllDelayRed"],&[for="flightStatusChangeAllCancelled"] {
      &:after {
        background-color: #F25050;
      }
    }
  }
}
.cust-cb {
  &.cust-weather{
    label {
    position: relative;
    padding: 0px 0 0 35px !important;

    &:after {
      width: 36px;
      height: 36px;
    }
  }
  .child-label label:after {
    top: -5px;
    left: 28px;
}}
  label {
    position: relative;
    padding: 0px 0 0 35px !important;
    pointer-events: none;
    &:after {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      position: absolute;
      top: -1px;
      left: 36px;
      border-radius: 50%;
    }

    &[for="black"] {
      &:after {
        background: black;
      }
    }

    &[for="red"] {
      &:after {
        background-color: #F25050;
      }
    }

    &[for="yellow"] {
      &:after {
        background-color: #FFB803;
      }
    }

    &[for="green"] {
      &:after {
        background-color: #3DBC56;
      }
    }
    &[for="early"] {
      &:after {
        background-color: #3DBC56;
      }
    }
    &[for="ontime"] {
      &:after {
        background-color: #3DBC56;
      }
    }
    &[for="delayGreen"] {
      &:after {
        background-color: #3DBC56;
      }
    }
    &[for="delayYellow"] {
      &:after {
        background-color: #FFB803;
      }
    }
     &[for="delayRed"] {
      &:after {
        background-color: #F25050;
      }
    }
    &[for="cancelled"] {
      &:after {
        background-color: #F25050;
      }
    }
    &[for="diverted"] {
      &:after {
        background-color: #F25050;
      }
    }
  }
}
.btn-cnt{
  .btn{
    &_blu{
    width: 240px;
    height: 60px;
  }
  }
}
.inner-span{
  padding-left: 10px;
}
.settings-body__title{
  &.notifications-title{
    margin: 40px 0px 0px 25px !important;
  }
  margin: 40px 0px 0px 65px !important;
  &.settings-body-align{
    margin: 35px 0px 0px 50px !important;
  }
}
.travelers-container{
  background-color: #F0F3F8;
  height: 100vh;
}
.travelers-content{
  padding: 27px 0px 0px 43px !important;
  height: 100vh;
  .search-block{
    width: 292px;
    height: 28px;
  .head-row{
    padding-bottom : 25px;
  }
  }
}
.import-travelers-btn{
  height: 37px;
  width: 160px;
  color: #595B60 !important;
  background-color: #F0F3F8 !important;
  border-radius: 6px !important;
  font-family: "Open Sans";
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 22px !important;
  text-align: center !important;
  padding: 6px !important;
  box-shadow: 0 10px 20px 0 #DFDFDF;
}
.travelers-search-block{
  display: flex;
  align-items: center;
  .filter-head{
     img{
    margin-left: 24px;
    margin-bottom: 4px;
  }
  }
}
.filter-close{
  padding-right: 10px;
}
.filter-line{
  margin-bottom: 20px;
}
.filter-heading{
  font-size: 14px;
  font-weight: $fw-bold;
  color:#595B60;
  display: flex;
  p{
    margin: 5px 0 0px 0px;
  }
  img{
    padding-left: 0.5rem;
  }
}
.content-layer-container{
  background-color: #F0F3F8;
  height: 100vh;
}
.weather-icon{
  width:26px;
}
.select-label-align{
  .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    font-size: 16px !important;
    color: #000000;
    padding: 8px 0 0 0 !important;
    line-height: 0px!important;
  }
  .fa-angle-down:before {
    content: "\f107";
    color: #002D72 !important;
    position: absolute;
    top: 9px;
    right: 17px;
}
}
.notification-container{
  display: flex;
  padding-left: 1rem;
  .card2{
    width: 480px !important;
    padding: 13px 0 19px 13px !important;
    min-height: 640px !important;
  }

  .notification-head{
    font-family: 'Open Sans',sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: #33333B;
    margin-left: 12px;
  }
  }
  .pdgl-35{
    padding-left: 35px;
  }

.health-withIn {
  label {
    position: relative;
    padding: 0px 0 0 37px !important;

    &[for="healthAlert1"] {
      &:after {
        background: url("../../styles/images/Health\ Alerts/Level-01.svg") no-repeat
      }
    }
    &[for="healthAlert2"] {
      &:after {
        background: url("../../styles/images/Health\ Alerts/Level-02.svg") no-repeat
      }
    }
    &[for="healthAlert3"] {
      &:after {
        background: url("../../styles/images/Health\ Alerts/Level-03.svg") no-repeat
      }
    }
    &[for="travelAdvisory1"] {
      &:after {
        background: url("../../styles/images/Travel\ Advisories/Level-01.svg") no-repeat
      }
    }
    &[for="travelAdvisory2"] {
      &:after {
        background: url("../../styles/images/Travel\ Advisories/Level-02.svg") no-repeat
      }
    }
    &[for="travelAdvisory3"] {
      &:after {
        background: url("../../styles/images/Travel\ Advisories/Level-03.svg") no-repeat
      }
    }
    &[for="travelAdvisory4"] {
      &:after {
        background: url("../../styles/images/Travel\ Advisories/Level-04.svg") no-repeat
      }
    }
     &[for="journey1"] {
      &:after {
        background: url("../../styles/images/Journey\ Alerts/01-Flight-Boarded.svg") no-repeat
      }
    }
    &[for="journey2"] {
      &:after {
        background: url("../../styles/images/Journey\ Alerts/02-Flight-Change.svg") no-repeat
      }
    }
    &[for="journey3"] {
      &:after {
        background: url("../../styles/images/Journey\ Alerts/03-Flight-Not-Boarded.svg") no-repeat
      }
    }
    &[for="journey4"] {
      &:after {
        background: url("../../styles/images/Journey\ Alerts/04-Hotel-Checkin.svg") no-repeat
      }
    }
    &[for="journey5"] {
      &:after {
        background: url("../../styles/images/Journey\ Alerts/05-Hotel-Checkout.svg") no-repeat
      }
    }
    &[for="weather1"] {
      &:after {
        background: url("../../styles/images/Weather\ Alerts/Level-01-Outlook.svg") no-repeat
      }
    }
    &[for="weather2"] {
      &:after {
        background: url("../../styles/images/Weather\ Alerts/Level-02-Advisory.svg") no-repeat
      }
    }
    &[for="weather3"] {
      &:after {
        background: url("../../styles/images/Weather\ Alerts/Level-03-Watch.svg") no-repeat
      }
    }
    &[for="weather4"] {
      &:after {
        background: url("../../styles/images/Weather\ Alerts/Level-04-Warning.svg") no-repeat
      }
    }
  }
}
.xd{
  .form-group{
    .cust-rb{
        label {
  position: relative;
  padding: 0px 0 0 25px !important;
}
    }
  }
}
.flight-status-align{
  padding: 16px 0 0 0;
}
.flight-status-head{
  padding-bottom: 6px;
}
.airport-status-head{
  padding: 10px 0 0 0;
}
.health-head-align{
  margin: 0 12px 0 0;
}
.health-alert-status{
  padding-bottom: 6px;
}
.top-panel{
  padding: 0 0 25px 0;
}
.journey-header,.weather-header,.health-header,.advisory-header{
   padding: 0 0 12px 0;
}
.clear-filter{
  cursor: pointer;
}
.freq{
  padding: 0 !important;
}
.tInfo{
  color: #66686D;
  font-family: "Open Sans";
  font-size: 14px;
  position: absolute ;
}
.travelers-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}
.active-field{
  height: 270px;
  width: 520px;
  border-radius: 10px;
  background-color: #FDFEFE;
  box-shadow: 12px 8px 44px -12px rgba(27,58,146,0.16);
  padding: 15px !important;
  margin-bottom: 50px !important;
  margin: 15px 40px 25px;
}
.flights-field{
  height: 392px;
  width: 520px;
  border-radius: 10px;
  background-color: #FDFEFE;
  box-shadow: 12px 8px 44px -12px rgba(27,58,146,0.16);
  margin: 15px 15px 25px;
  padding: 13px !important;
}
.airport-field{
  height: 280px;
  width: 520px;
  border-radius: 10px;
  background-color: #FDFEFE;
  box-shadow: 12px 8px 44px -12px rgba(27,58,146,0.16);
  padding: 5px 11px !important;
  position: absolute;
  bottom: 85px;
}
.af-container{
  height: 280px;
  margin-left: 40px;
  position: relative;
}
.btn-set{
position: relative;
height: 60px;
.btn-cnt{
position: absolute;
bottom: 0;
}
}
.radio-cnt {
&.mdx{input:not(checked) + label:after {
   display: none !important;
}
input:not(checked) + label:before {
    border-radius: 50%;
    border-color: #AAAAAA !important;
}
}
.form-group .cust-rb input {
    vertical-align: top;
    margin: 0;
    width: 20px;
    height: 20px;
}
}
.color-coding-label,.only-vip-label,.input-size{
  &.cust-cb input {
    vertical-align: top;
    margin: 0;
    width: 20px;
    height: 20px;
}
}
.child-label{
  div{
    input{
      left: -8px;
    }
  }
}
.t-close{
  padding-left: 10px;
  width: 34px;
  height: 24px;
}
