.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}


.slide-enter {
  height: 0;
}

.slide-enter.slide-enter-active {
  height: auto;
  transition: height 300ms ease-in;
}

.slide-leave {
  height: auto;
}

.slide-leave.fade-leave-active {
  height: 0;
  transition: height 300ms ease-in;
}

// preloader
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);

  .loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.loading-icon:before,
.loading-icon:after,
.loading-icon {
  border-radius: 50%;
  width: 5em;
  height: 5em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
}
.loading-icon {
  color: $base-bg-color;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loading-icon:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loading-icon:after {
  left: 3.5em;
}
.loading-icon:before,
.loading-icon:after {
  content: '';
  position: absolute;
  top: 0;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

