@import "../../styles/variables";
@import "../../styles/mixins";

.activee {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.active-travelers-col {
  // width: $left-col-width;
  // width: 450px;
  // padding-left: 140px !important;
  position: relative;
  // display: flex;
  float: left;
  color: lighten(
    desaturate(adjust-hue($primary-text-color, 0), 1.7172),
    6.6667
  );
  border-right: 2px solid #d5d7db;
  background-color: #f5f6f7;
  scrollbar-color: #007680 #f0f0f0;
  scrollbar-width: thin;

  .active-travelers-col-heads {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e2e5e9;
    padding-bottom: 7px;
  }
  .active-travelers-col-head {
    height: 843px;
    // width: 420px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 10px;
    padding: 13px 15px;
    // background-color: #F5F6F7;
    // box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .11);
    height: 85px;
    padding-top: 11.5px;
    border-bottom: 1px solid #e2e5e9;
  }

  a {
    color: lighten(
      desaturate(adjust-hue($primary-text-color, 0), 1.7172),
      6.6667
    );
  }

  .sticky-is-fixed > div {
    padding: 0;
  }

  .status-list {
    @include reset-list();
    // color: $secondary-text-color;
    font-size: $font-size-xs;
    float: right;
    color: #595b60;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.35px;
    line-height: 16px;
    padding-top: 13px;
    padding-bottom: 0px;

    li {
      float: left;
      margin-right: 13.5px;

      & + li {
        padding-left: 8px;
        margin-right: 2px;
      }
    }

    strong {
      font-weight: $fw-regular;
    }

    .icon {
      // @extend %icon-min;
      float: left;
      margin-right: 4px;
      width: 16px;
      height: 16px;

      &.warning {
        margin: -2px 3px 0 0;
      }
    }
    .status-list-icon{
      width: 16px;
      margin-right: 6px;
    }
  }

  .travelers-filter {
    &__btn {
      height: 24px;
      width: 131px;
      // padding: 5px 12px 5px 26px;
      // border: 1px solid #D4D7DD;
      border-radius: $small-border-radius;
      color: #595b60;
      font-family: "Open Sans";
      font-size: 13px;
      letter-spacing: 0;
      line-height: 24px;
      font-size: 14px;
      // background: transparent url("./../../styles/images/icon-filter.png") no-repeat 10px center;
      background-size: 14px;
      transition: opacity 0.2s;
      padding-left: 5px;
      .filt {
        padding-right: 6px;
        width: 24px;
      }

      &:hover,
      &.active {
        opacity: 0.7;
      }
    }

    $dropdown-whitespaces: 12px 14px;

    &__dropdown {
      // position: relative;

      .dd-menu-items {
        // left:63% !important;
        // transform: translateX(-51%) !important;
        // z-index: 10000;

        .dd-items-center {
          // display: flex;
          // flex-wrap: nowrap;
          // flex-direction: column;
          width: 420px;
          border-radius: $small-border-radius;
          //border: 1px solid #d4d7dd;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0);
          position: absolute;
          margin: -51px -210px;
          font-family: "Open Sans";
          background-color: #ffffff;
          height: 100vh;
        }
      }

      hr {
        display: block;
        width: 100%;
        border: 0;
        border-top: 0.5px solid #f1f2f4;
        margin: 0px;
        margin-top: 20px !important;
        // margin: 8px 14px;
      }
    }

    &__dropdown-title {
      color: #EAAA02;
      font-family: "Open Sans";
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      padding-left: 6px !important;
      .filt {
        padding-right: 6px;
        color: #EAAA02;
      }
    }
    &__dropdown-titl {
      color: #595b60;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.35px;
      line-height: 16px;
      margin: 4px 6px;
      padding-top: 13px;
      // padding-bottom: 13px
    }

    &__dropdown-title-cnt {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__dropdown-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #DFDFDF;
      border-top: 1px solid #DFDFDF;
      padding: 7px 4px 7.5px 12px;
      // padding: $dropdown-whitespaces;
      margin: 0px;
    }

    &__dropdown-option-title {
      margin: 4px 6px;
      font-weight: normal;
    }

    &__dropdown-body {
      padding: 6px 0;
    }

    &__dropdown-option-holder {
      // padding-bottom: 20px;
    }

    &__dropdown-status-selector {
      box-shadow: none !important;
      position: relative;

      .icon {
        margin-right: 3px;
      }

      li {
        display: flex !important;
        align-items: center;

        &:hover {
          background-color: #edf7fc !important;
        }
      }

      label {
        display: flex !important;
        padding: $dropdown-whitespaces !important;
        align-items: center;
        position: relative;
      }

      input[type="checkbox"] {
        display: none !important;

        &:checked + label {
          &:after {
            content: "";
            display: block;
            width: 16px;
            height: 15px;
            position: absolute;
            right: 14px;
            top: 12px;
            background: url("./../../styles/images/icon-checkbox.png") no-repeat;
            transform: scale(0.9, 0.9);
          }
        }
      }
    }

    &__dropdown-footer {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 6px;
      padding-bottom: 13px;
      // border-top: 1px solid #D5D7DB;
      // padding: $dropdown-whitespaces;
      .select-button{
        border-radius: 6px;
        background-color: #007680;
        height: 37px;
        width: 100px;
        border: 0;
        border-radius: 6px;
        padding: 0px;
        color:#ffffff;
      }
      .clear-button {
        height: 37px;
        width: 100px;
        // background-color: #007680;
        background-color: #DFDFDF;
        border: 0;
        border-radius: 6px;
        padding: 0px;
        pointer-events: none;
        .clear-all{
          color: #595B60 !important;
        }
      }

      .clear-all {
        color: #ffffff;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
      }
    }

    &__close-btn {
      background: none;
      // padding: 1px;
      border: 0;
    }

    &__icon-global-filter {
    }

    .cust-holder {
      padding: 17px 0 0;
      float: none;
      clear: both;
    }

    .select-holder {
      float: right;
    }

    .Select {
      width: 270px;
      margin: 0;
    }
  }
}
.Select {
  padding-left: 6px !important;
}

.travelers-list-cntr {
  max-height: 100%;

  overflow-y: scroll;
  height: 80vh;

  @media (min-height: 1075px) {
    height: 84vh;
  }

  @media (min-width: 2386px) and (max-width: 950px) {
    height: 90vh;
  }

  @media (min-width: 768px) and (max-width: 950px) {
    height: 90vh;
  }

  .travelers-list {
    @include reset-list();

    &__item {
      display: flex;
      align-items: center;
      padding: 13px 10px 9px;
      position: relative;
      width: 100%;
      border-bottom: 1px solid #e2e5e8;

      & > div {
        width: 100%;
      }

      .traveler-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .icon {
        float: left;
        margin-right: 7px;
      }

      .traveler-info {
        color: $primary-text-color;
        overflow: hidden;

        .name {
          padding-bottom: 5px;
          display: flex;
          //justify-content: center;
          align-items: center;
          .status-icon{
            width: 25px;
            margin: 0 8px 2px 0px;
          }
        }

        .travelers-name {
          font-size: $font-size-l;
          font-weight: $fw-semi-bold;
          display: inline-block;
          padding-right: 5px;
        }
      }

      .helped-block {
        float: right;
        color: $secondary-text-color;
        font-size: $font-size-xs;
        text-align: right;
        max-width: 186px;

        .helpedMsg {
          display: inline-block;
          vertical-align: middle;
          padding: 0 5px;
          line-height: $line-height-s * 1.5;
        }
      }
    }
  }

  // .events-wrap {
  //   // padding-top: 17px;
  // }

  .events {
    @include reset-list();
    background-color: lighten(
      desaturate(adjust-hue($blue-color, 1.1834), 5.7404),
      38.8235
    );
    padding: 1px 0 12px 29px;
    font-size: 14px !important;
    font-family: "open sans" !important;
    letter-spacing: 0 !important;
    line-height: 16px !important;
    color: #070f29;

    li {
      position: relative;
      padding: 10px 0px 4px;
      margin-left: -10px;
      @include clearfix();

      &.current-position {
        border-color: #EAAA02;
        padding: 0;
        width: 100% vh;
        height: 1px;
        background-color: #EAAA02;
        margin-left: -11px;

        &:before {
          content: "";
          position: absolute;
          left: -19px;
          top: -9px;
          width: 20px;
          height: 20px;
          background: url("./../../styles/images/Traveler.png") no-repeat;
        }
      }

      span {
        display: inline-block;
        vertical-align: top;
      }

      & > span {
        float: left;
      }

      .date-hold {
        width: 124px;
      }

      .event-info {
        width: 226px;
        float: left;
      }
      .status {
        float: right;
        margin-right: -45px;
      }
      .text-holder {
        overflow: hidden;
        // padding-left: 10px;
      }
    }

    .time {
      color: $disabled-text-color;
      padding-right: 5px;
    }

    .type {
      font-weight: $fw-bold;
    }

    .sep {
      position: relative;
      padding: 0 6px;

      &:after {
        content: ".";
        display: inline-block;
        width: 3px;
        height: 3px;
        background-color: lighten(
          desaturate(adjust-hue($primary-text-color, -7.5), 3.0622),
          15.6863
        );
        vertical-align: middle;
      }
    }
  }
  .btn02 {
    min-width: 64px;
  }
}
.status {
}

.map-col {
  // display: flex;
  // position: relative;
  width: calc(100% - #{$left-col-width});
  float: left;
}

.leaflet-popup-content-wrapper {
  box-shadow: $popup-shadow;
}

.mapboxgl-popup {
  padding: 0;
  font-size: $font-size-xs;
  line-height: $line-height-l;
  color: lighten(
    desaturate(adjust-hue($primary-text-color, 0), 1.7172),
    6.6667
  );

  p {
    font-size: $font-size-xs;
    line-height: $line-height-l;
  }

  ul {
    @include reset-list();
    line-height: $line-height-default;
    padding: 8px;
  }

  li {
    @include clearfix();
    //display: inline-block;
    padding-bottom: 8px;
    vertical-align: middle;
    width: 100%;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .icon {
    float: left;
    margin-right: 6px;
  }

  .vip {
    @extend %vip-min;
  }

  .popup-heading {
    overflow: hidden;
    padding-top: 8px;
    padding-bottom: 8px;
    //padding-top: 20px;
    display: flex;

    .icon {
      float: left;
      margin: 7px 10px 0 0;
    }

    .holder {
      overflow: hidden;
    }
  }
  .area {
    padding-top: 8px;
    border-top: 1px solid $gray-color;

    span {
      display: block;
    }

    .muted {
      color: $secondary-text-color;
    }

    .airport-status {
      position: relative;
      display: block;
      background: none;
      color: $primary-text-color;

      &:before {
        position: absolute;
        top: 3px;
        left: 0;
        width: 10px;
        height: 10px;
        background-color: $disabled-color;
        border-radius: 50%;
      }

      &.green {
        &:before {
          background-color: $ok-color;
        }
      }
      &.red {
        &:before {
          background-color: $alarm-color;
        }
      }
      &.orange {
        &:before {
          background-color: lighten(
            saturate(adjust-hue($yellow-color, -30.283), 0),
            8.4314
          );
        }
      }
      &.yellow {
        &:before {
          background-color: lighten(
            saturate(adjust-hue($yellow-color, -7.7064), 0),
            7.2549
          );
        }
      }
      &.black {
        &:before {
          background-color: $primary-text-color;
        }
      }
    }
    .user-icon {
      position: relative;
      display: block;
      padding: 0 0 5px 19px;

      &:before {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        width: 15px;
        height: 10px;
        background: url("./../../styles/images/icon-sprites.png") no-repeat -429px -88px;
      }
    }
  }
  .dep-des {
    .holder {
      @include clearfix();
      //border-top: 1px solid #f1f2f4;
      //border-bottom: 1px solid #f1f2f4;

      & > div {
        float: left;
        width: 50%;
        padding: 8px;

        strong {
          display: inline-block;
          max-width: 49%;
        }

        del {
          max-width: 48%;
        }
      }
    }
    .arrow_box {
      position: relative;
      background: $white-color;
      border-right: 1px solid
        lighten(saturate(adjust-hue($gray-color, -2.8571), 2.1408), 9.0196);
    }
    .arrow_box:after,
    .arrow_box:before {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .arrow_box:after {
      border-left-color: $white-color;
      border-width: 5px;
      margin-top: -5px;
    }
    .arrow_box:before {
      border-left-color: lighten(
        saturate(adjust-hue($gray-color, -2.8571), 2.1408),
        9.0196
      );
      border-width: 6px;
      margin-top: -6px;
    }
  }

  h3 {
    margin: 0;
    border-bottom: 1px solid
      lighten(saturate(adjust-hue($gray-color, -2.8571), 2.1408), 9.0196);
    padding: 5px 8px;
    color: $primary-text-color;
    font-size: $font-size-xs;
    font-weight: $fw-bold;
  }

  h4 {
    margin: 0;
    color: $primary-text-color;
    font-size: $font-size-xs;
    font-weight: $fw-bold;
  }
  .head {
    //padding: 5px 8px;

    h4 {
      position: relative;
      display: inline-block;
      padding-right: 16px;
      line-height: $line-height-default;
    }

    strong {
      display: block;
    }
  }
  strong {
    &.warning {
      color: $warning-color;
      background: none;
      font-weight: $fw-regular;
    }
    &.ok {
      color: $ok-color;
      background: none;
      font-weight: $fw-regular;
    }
    &.alarm {
      color: $alarm-color;
      background: none;
      font-weight: $fw-regular;
    }
  }
  .fly {
    @include hide-text();
    position: absolute;
    top: 50%;
    margin-top: -5px;
    right: 0px;
    width: 10px;
    height: 10px;
    background: url("./../../styles/images/icon-sprites.png") no-repeat -266px -134px;
  }

  del {
    color: $disabled-text-color;
    display: inline-block;
  }
}

#popup-flights {
  display: table;
  font-size: $font-size-xs;
  margin-bottom: -13px;

  .tabset {
    display: table-cell;
    border-right: 1px solid $gray-color;


    ul {
      @include reset-list();
      margin-bottom: 10px;

      li {
        float: none;
        padding: 4px 8px;
        cursor: pointer;
        overflow: hidden;
        border-left: none;

        &:hover {
          background-color: lighten(
            desaturate(adjust-hue($blue-color, 1.1834), 5.7404),
            38.8235
          );
        }
        span {
          float: left;
          position: relative;
          padding-right: 3px;
        }
        strong {
          float: right;
        }
      }
    }
  }
  .tabs-holder {
    display: table-cell;
    width: 264px;
  }
}

.popup-travelers-list {
  padding: 5px 8px;

  .comp-name {
    color: $disabled-text-color;
  }
}
.threetabs {
  background-color: White;
  width: 450px !important;
}
.Disruption {
  width: 200px;
  height: 27px;
  margin-left: 6px;
  // border: 1px solid #AAAAAA !important;
}

.Health {
  font-size: 16px;
  font-weight: bold;
  color: #007680;
  // padding-left: 6px;
  margin-bottom: 0px !important;
  // padding-bottom: 13px !important;
}
.Security {
  font-size: 16px;
  font-weight: bold;
  color: #007680;
  // padding-left: 6px;
  margin-bottom: 0px !important;
  // padding-bottom: 13px !important;
}
.poi {
  font-size: 16px;
  font-weight: bold;
  color: #007680;
  // padding-left: 6px;
  margin-bottom: 0px !important;
  // padding-bottom: 13px;
}
.active-travelers-col .status-list .alarm.icon {
  width: 16px !important;
  height: 16px !important;
}
.active-travelers-col .status-list .alarm.icon::after {
  background-position: none;
}
// .disruption-selected{
//   .Select-control{
//     margin-bottom: 20px !important;
//   }
//   .Select-menu-outer{
//     border-bottom:2px solid #007680 !important;
//     border-left:2px solid #007680 !important;
//     border-right: 2px solid #007680 !important;
//     border-top: none !important;
//     border-bottom-right-radius: 4px !important;
//     border-bottom-left-radius: 4px !important;
//     margin-left: 6px !important;
//   }
// }

.disruption-status {
  .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: #333;
    padding: 0;
    position: absolute;
    bottom: -5px;
}
  p {
    font-size: 16px;
    font-weight: bold;
    color: rgb(21, 69, 145);
    margin-left: 6px !important;
    margin-bottom: 12px !important;
  }
  .Select-control {
    width: 200px !important;
    height: 27px !important;
    border-radius: 4px !important;
    border: 1px solid #aaaaaa !important;
    // border-bottom-right-radius: 4px !important;
    // border-bottom-left-radius: 4px !important;
    font-size: 13px !important;
    margin-bottom: 20px !important;
  }
  .Select-menu-outer {
    width: 200px !important;
    overflow: hidden !important;
  }
  .Select-Placeholder {
  }
}
// .Dicon{
//   display: flex !important;
//   flex-direction: row !important;
//   padding-left: 12px !important;
//   // text-align: center !important;
//   align-items: center !important;
//   // margin-bottom: 22px !important;
//   i{
//       width: 16px !important;
//       height: 16px !important;
//   }
//   .Dlabel{
//       color: #595B60 !important;
//       font-family: "Open Sans" !important;
//       font-size: 13px !important;
//       letter-spacing: 0 !important;
//       // line-height: 23px !important;
//       font-weight: normal !important;
//       padding-left: 12px !important;
//       // margin-bottom: 12px !important;
//   }
// }
.ActiveTraveler-label {
  display: flex !important;
  justify-content: center !important;

}

.ActiveTraveler-icon {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  //margin-bottom: 32px !important;
  margin-top: -1rem !important;
  padding-left: 12px !important;
  padding-bottom: 0px !important;
  .ActiveTraveler-Dlabel {
    padding-left: 22px !important;
  }
}

.date-range {
  margin-left: 6px !important;
  .filter-dateranges {
    .filter-daterange {
      height: 27px !important;
      width: 81px !important;
      color: #595b60 !important;
      font-family: 'Open Sans', sans-serif !important;
      font-size: 13px !important;
      letter-spacing: 0 !important;
      line-height: 17px !important;
      text-align: center !important;
      font-weight: 400;
    }
    .to-label {
      color: #595b60 !important;
    }
  }
}


.continent-select{
  .Select-control{
    //display: flex !important;
    align-items: center !important;
    border: 1px solid #aaaaaa;
    justify-content: end !important;
    width: 180px !important;
    height: 27px !important;
    font-size: 13px !important;
    font-family: "open sans";
    color: #595b60 !important;
    border-radius: 4px !important;
  }
  .Select-menu-outer {
    // border: 2px solid #007680 !important;
    display: flex !important;
    flex-direction: column !important;
    border-bottom: 2px solid #007680 !important;
    border-left: 2px solid #007680 !important;
    border-right: 2px solid #007680 !important;
    border-top: none !important;
    // border-radius: 4px !important;
    // border-top-right-radius: 4px !important;
    // border-top-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    margin-left: 6px !important;
    margin-bottom: 10px !important;
    scrollbar-width: thin !important;
    width: 180px !important;
  }
  .Select-placeholder {
    display: flex !important;
    align-items: center !important;
  }
  label {
    padding-left: 12px !important;
    // .continent-label{
    //  margin-left: 20px !important;
    //  padding-top: 20px !important;
    // }
  }
  &.active-continent{
    .Select-menu{
      overflow: unset;
      height: 100%;
    }
    .Select-menu-outer {
      min-height: 218px !important;
  }}
  &.xs{
    .Select-menu{
      height: 100%;
    }
  }
}
.xs{
  .Select-menu{
      height: 100%;
    }
}
.country-select{
  .Select-control{
    //display: flex !important;
    align-items: center !important;
    border: 1px solid #aaaaaa;
    justify-content: end !important;
    width: 200px !important;
    height: 27px !important;
    font-size: 13px !important;
    font-family: "open sans";
    color: #595b60 !important;
    border-radius: 4px !important;
  }
  .Select-menu-outer {
    // border: 2px solid #007680 !important;
    display: flex !important;
    flex-direction: column !important;
    border-bottom: 2px solid #007680 !important;
    border-left: 2px solid #007680 !important;
    border-right: 2px solid #007680 !important;
    border-top: none !important;
    // border-radius: 4px !important;
    // border-top-right-radius: 4px !important;
    // border-top-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    margin-left: 6px !important;
    margin-bottom: 10px !important;
    scrollbar-width: thin !important;
  }
  .Select-placeholder {
    display: flex !important;
    align-items: center !important;
  }
  label {
    padding-left: 12px !important;
    // .continent-label{
    //  margin-left: 20px !important;
    //  padding-top: 20px !important;
    // }
  }
}

.state-select{
  .Select-control{
    //display: flex !important;
    align-items: center !important;
    border: 1px solid #aaaaaa;
    justify-content: end !important;
    width: 180px !important;
    height: 27px !important;
    font-size: 13px !important;
    font-family: "open sans";
    color: #595b60 !important;
    border-radius: 4px !important;
  }
  .Select-menu-outer {
    // border: 2px solid #007680 !important;
    display: flex !important;
    flex-direction: column !important;
    border-bottom: 2px solid #007680 !important;
    border-left: 2px solid #007680 !important;
    border-right: 2px solid #007680 !important;
    border-top: none !important;
    // border-radius: 4px !important;
    // border-top-right-radius: 4px !important;
    // border-top-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    margin-left: 6px !important;
    margin-bottom: 10px !important;
    scrollbar-width: thin !important;
  }
  .Select-placeholder {
    display: flex !important;
    align-items: center !important;
  }
  label {
    padding-left: 12px !important;
    // .continent-label{
    //  margin-left: 20px !important;
    //  padding-top: 20px !important;
    // }
  }
}


.fa .fa-angle-down {
  height: 7.12px !important;
  width: 12px !important;
  background-color: #aaaaaa !important;
}

.Select-clear {
  font-size: 0px !important;
}
.disruption-select{

  &.sel{
    .Select-option{
      position: relative;
      padding: 5px 0 3px 0;
    }
    .Dicon .Dlabel {
    position: absolute;
    left: 48px;
    top: -4px;
}



    }
.Select-option{
  position: relative;
  padding: 0 10px;
}
.Dicon .Dlabel{
  position: absolute;
  left: 60px;
  top: 5px;
}
.Select-menu{
  overflow-y: hidden;
  height: 100% !important;
}
&.cx{
  .Select-option {
    padding: 8px 10px;
}
.Select-menu{
  width: 100% !important;
  height: 100% !important;
}
  .Select-placeholder {
    color: #595B60;
  }
}
}
.width25rem{
  width: 25rem;
}

$breakpoint-tablet: 806px;
@media (min-height: $breakpoint-tablet) {
  .activeTravellerMQfix{
    height: 85vh;
    overflow-y: auto;
  }
}

$breakpoint-4k: 1151px;
@media (min-height: $breakpoint-4k) {
  .activeTravellerMQfix{
    height: 88vh;
    overflow-y: auto;
  }
}
.travel-ico{
  padding-right: 10px;
}
.disabled-op{
  .Select-placeholder{
    color:#aaa !important;
    cursor: not-allowed;
  }
}
.active-trvlr .Select-option {
  height:28px;
  span > input[type=checkbox] {
    top:4px
}
}
.user-list{
p{
  &.travelerName a{
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: #3192FF;
    font-family: 'Open Sans', sans-serif;
    text-transform: capitalize;
  }
}
}
.popup-travel-advisory .contentbox .user-list .row .info .travelerorg{
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
    text-transform: capitalize;
  }
.save-status{
  background-color: #4cc07b !important;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 9999;
  top: 70px;
  left: 450px;
}

#atMap {
  position: absolute;
}

#atMap1 {
  position: absolute;
}

.active-travelers-col {
  .MuiButtonBase-root {

    .MuiTouchRipple-root {
      border-left: 1px solid lightgrey;
      height: 17px;
      top: 12px;

    }

  }
  .MuiButtonBase-root.Mui-selected {

    .MuiTouchRipple-root {
      border-left: none;
      height: 17px;
      top: 12px;

    }

  }
}

.mapboxgl-popup-content {
  //padding: 28px 10px 15px;
}
.cover-screen{
  width: 102% !important;
}
@media(min-width:1950px){
  .cover-screen{
    width: 101% !important;
  }
}
