.legend{
    background-color: #fff;
    z-index: 1;
    position: absolute;
    left: 20px;
    bottom: 20px;
      padding: 10px;
    width: 233px;
    border-radius: 14px;
    .Select-control{
        border: 1px solid #000000;
        border-radius: 4px;
        width: 10rem;
        height:0px !important;
        margin-left: -4px;
    }

.Select.has-value.Select--single >
.Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single >
.Select-control .Select-value .Select-value-label {
    color: #000000;
    font-family: "Open Sans";
    font-size: 13px;
    position: absolute;
    top:-1px;
    left: 10px;
}
.Select-menu-outer{
    width: 10rem;
    margin-left: 2px;
}
.fa-angle-down::before {
    color: #000000 !important;
}
}
.legend-heading{
  color: #000000;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
}
.legend-head-img{
height: 20px;
width: 20px;
position: absolute;
right: 10px;
top: 10px;
cursor:pointer;
}
.legend-head-img2{
height: 20px;
width: 20px;
position: absolute;
right: 10px;
top: 10px;
transform: rotate(180deg);
z-index: 99999;
cursor:pointer;
}
.legend-subheading{
  color: #007680;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  padding-top: 0.7rem;
  &.pdgt{
    padding-top: 4px !important;
  }
  &.flights{
      padding-top: 10px;
  }
}
.legend-row{
        margin-bottom: 8px;
        padding-block-end: 5px;
        padding-left: 20px;
        position: relative;
    input{
        margin-right: 10px;
    }
    span{
          display: inline-block;

          height: 15px;
          margin-right: 8px;
          width: 15px;
          border-radius: 9px;
          position: absolute;
          top: 2px;
          left: -2px;
    }
}
.compare-container{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
}


.Select-menu {
  //height:6rem;
}


.Select-input{
    height:26px;
}

.Select-arrow-zone{
  top:1px;
}

.mapboxgl-popup-content {
  border-radius: 6px;
}

.mapFilters {
  .Select-placeholder, .Select--single > .Select-control .Select-value {
    top: -5px;
  }
}


.travel-advisory-holder {
  .legend{
    background-color: #fff;
    z-index: 1;
    position: absolute;
    left: 29rem;
    bottom: 20px;
    padding: 9px;
    width: 233px;
    border-radius: 14px;
  }
}
.filter-check{
  &.cust-cb input:checked + label:before {
    position: absolute;
    top:-4px;
  }
  &.cust-cb input:not(checked) + label:before{
    position: absolute;
    top: -4px !important;
  }
}

.travel-advisory-holder {
  .travelersMap{
    .legend{
      background-color: #fff;
      z-index: 1;
      position: absolute;
      bottom: 20px;
      left: 1rem;
      padding: 9px;
      width: 233px;
      border-radius: 14px;
    }
  }
}


.bottom6rem {
  bottom: 6rem;
}


.bottom11rem {
  bottom: 11rem;
}
