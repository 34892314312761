.invalid {
  color: red;
}

.cust-cb {
  float: left;
  font-size: $font-size-default;
  line-height: 18px;
  margin-right: 34px;
  &.is-vip{
    input{
      &:not(checked) + label:before{
        top: -5px;
      }
    }
  }
  input {
    vertical-align: top;
    margin: 0;
    width: 15px;
    height: 14px;

    &:not(checked) {
      position: absolute;
      opacity: 0;
    }

    &:not(checked) + label {
      display: inline-block;
      position: relative;
      padding: 0 0 0 28px;
    }

    &:not(checked) + label:before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: #fff;
      border: 1px solid #d8dadf;
      border-radius: 4px;
    }
    &:checked + label:before {
      background-image: url("images/Checkbox-Active.svg");
      background-repeat: no-repeat;
      background-position: center center;
      border-color: #007680;
    }
    &:focus + label:before {
      border-color: $link-color;
    }
  }
}

.cust-rb {
  float: left;
  font-size: $font-size-default;
  line-height: 18px;
  margin-right: 34px;

  input {
    vertical-align: top;
    margin: 0;
    width: 15px;
    height: 14px;

    &:not(checked) {
      position: absolute;
      opacity: 0;
    }
    &:checked + label:before {
      border: 1px solid #007680 !important;
    }
    &:not(checked) + label {
      display: inline-block;
      position: relative;
      padding: 0 0 0 28px;
    }

    &:not(checked) + label:before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: 18px;
      height: 18px;
      background-color: $white-color;
      border: 1px solid $gray-color;
      border-radius: 50%;
    }

    &:not(checked) + label:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 4px;
      width: 10px;
      height: 10px;
      background-color: #007680;
      border-radius: 50%;
      display: none;
    }
    &:checked + label:after {
      display: block;
    }
    &:focus + label:before {
      border-color: $link-color;
    }
    &[disabled] + label {
      opacity: 0.6;
    }
  }
}

.status-cb {
  @include reset-list();
  margin-right: 15px;
  float: left;

  li {
    float: left;

    &:first-child {
      label {
        border-radius: 3px 0 0 3px;
        border-right: none;
      }
    }

    &:last-child {
      label {
        border-radius: 0 3px 3px 0;
        border-left: none;
      }
    }
  }

  label {
    float: left;
    background: lighten(
                    desaturate(adjust-hue($gray-color, -12.8571), 4.596),
                    6.4706
    );
    border: 1px solid $gray-color;
    width: 32px;
    height: 32px;
  }

  .icon {
    margin: 3px auto 0;

    &.warning {
      width: 22px;
      height: 22px;
      background: none !important;

      &:after {
        background: url("images/icon-statuses.png") no-repeat 4px -41px;
      }
    }

    &.alarm {
      width: 18px;
      height: 18px;
      background-color: $alarm-color;
      margin-top: 7px;

      &:after {
        background: url("images/icon-statuses.png") no-repeat 2px 8px;
      }
    }

    &.ok {
      width: 21px;
      height: 21px;
      background: $ok-color;
      border-radius: 50%;
      margin-top: 6px;

      &:after {
        background: url("images/icon-statuses.png") no-repeat 4px -95px;
      }
    }
  }

  input {
    vertical-align: top;
    margin: 0;
    width: 15px;
    height: 14px;

    &:not(checked) {
      position: absolute;
      opacity: 0;
    }

    &:checked + label {
      background: $white-color;
    }
  }
}

.search-block {
  float: left;
  margin-top: 10px;
  position: relative;
  border: 1px solid #f2f2f2;

  input[name="search"],
  input[name="searchBar"] {
    border: 1px solid #f2f2f2;
    color: $disabled-text-color;
    font-size: $font-size-default;
    font-family: "Rubik", sans-serif;
    padding: 7px 0px 7px 6px;
    border-radius: 4px;
    outline: none;
    margin: 0;
    width: 420px;
    transition: all 0.3s ease;
    background-color: $white-color !important;
    background: url("images/Search-Button.svg") no-repeat right;

    &:focus {
      padding: 7px 30px 7px 10px;
      background-position: -22px 7px;
      color: $primary-text-color;
    }
  }

  &.small-grey {
    margin: 0;

    input[name="search"],
    input[name="searchBar"] {
      border: none;
      color: #595B60 !important;
      font-size: 13px;
      padding: 5px 0px 5px 6px;
      width: 380px;
      transition: all 0.3s ease;
      background: url("images/icon-search.png") no-repeat 360px 7px;
      background-size: 15px;
      height: 28px;

      &:focus {
        padding: 5px 30px 5px 8px;
        background-position: -22px 7px;
        color: #c1c6ce;
      }

      &::placeholder {
        color: #c1c6ce;
        font-size: 14px !important;
      }
    }
  }

  .remove {
    @include hide-text();
    position: absolute;
    right: 0;
    top: 1px;
    width: 31px;
    height: 31px;
    background: url("images/icon-sprites.png") no-repeat -100px -120px;
  }

  .search-drop {
    position: absolute;
    top: 33px;
    left: 0;
    background-color: $white-color;
    width: 100%;
    box-shadow: $popup-shadow;
    border-radius: 0 0 2px 2px;
    border-top: 1px solid lighten(saturate(adjust-hue($gray-color, -2.8571), 2.1408), 9.0196);
    max-height: 440px;
    overflow: auto;
    color: $disabled-text-color;
    z-index: 1;
    a {
      color: $primary-text-color;
      display: block;
      padding: 11px 12px 12px;

      &:hover {
        text-decoration: none;
        background: lighten(
                        saturate(adjust-hue($base-bg-color, -16.904), 54.3812),
                        58.8235
        );
      }
    }
    strong {
      display: block;
      font-weight: $fw-bold;
      word-wrap: break-word;
    }
    p {
      margin: 0;
      padding: 11px 12px 12px;
      word-wrap: break-word;
    }
    .search-p{
      padding: 2px 10px 0px 0 !important;
    }
    .highlight {
      background: $yellow-color;
      font-style: normal;
    }
  }
  &.borderless{
    border: none;
    width: 300px !important;
  }
}

.page-content,
.users-view,
.companies-view {
  background-color: #F0F3F8;
  .min-container {
    min-width: 675px;
    padding: 35px 45px !important;
    min-height: 100vh;
    &.company-min-container {
      .head-row {
        padding: 0 0 21px 0;
      }
    }
  }
}

.search-block {
  @include clearfix();
  float: none;
  margin-bottom: 15px;

  input[name="search"],
  input[name="searchBar"] {
    border-color: #f2f2f2;
    width: 100%;
    box-shadow: 0 2px 18px 0 rgba(41, 94, 255, 0.05);
  }
}

.page-content {
  .accounts-filter {
    input[name="search"],
    input[name="searchBar"] {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - #{470px});
      margin-right: 15px;
      padding-bottom: 8px;
    }

    .Select {
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      width: 118px;
    }

    .agency-select {
      display: inline-block;
      vertical-align: middle;

      .Select {
        width: 240px;
      }
    }
    .clear-all {
      display: inline-block;
      vertical-align: middle;
      padding-top: 10px;
    }
  }
}

.Select-control {
  // border-color: lighten(saturate(adjust-hue($gray-color, -6.8571), 0.3449), 4.3137);
  border-radius: 8px;
  height: 43px;
  font-size: 16px;
  line-height: 22px;
  box-shadow: 0 0 18px 1.2px rgba(41, 94, 255, 0.05);

  .Select-clear {
    //display: none;
  }
}

.is-focused > .Select-control,
.is-searchable.is-focused:not(.is-open) > .Select-control {
  border: 2px solid #007680 !important;
}

.company-select {
  .Select-menu-outer {
    border: 2px solid #007680;
    border-top: 0;
    width: 180px !important;
    color: #595b60;
    font-family: "Open Sans";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 23px;
    margin-left: 6px !important;

    input[type="checkbox"] {
      &:not(:checked),
      &:checked {
        // position: absolute;
        // left: -9999px;
      }

      &:not(:checked) + label,
      &:checked + label {
        // position: relative;
        // padding-left: 25px;
        cursor: pointer;
        color: #595b60 !important;
        font-family: "Open Sans" !important;
        font-size: 13px !important;
        letter-spacing: 0;
        // display: flex;
        // flex-direction: column;
        // width: 15px;
        height: 15px;
      }

      &:not(:checked) + label:before,
      &:checked + label:before {
        content: "";
        display: flex;
        flex-direction: column !important;
        // position: absolute;
        // left:0;
        // top: 2px;
        width: 15px;
        height: 15px;
        background-color: $white-color;
        border: 1px solid $gray-color;
        border-radius: 1px;
        color: #595b60 !important;
        font-family: "Open Sans" !important;
        font-size: 13px !important;
        letter-spacing: 0;
        // line-height: 23px;
      }

      &:not(:checked) + label:after,
      &:checked + label:after {
        content: "";
        // display: block;
        // position: absolute;
        // left:0;
        // top: 2px;
        width: 15px;
        height: 15px;
        // background: url("images/icon-checkbox.png") no-repeat center center;
      }

      &:not(:checked) + label:after {
        opacity: 0;
      }

      &:checked + label:after {
        opacity: 1;
      }
    }
    .Select-menu{
      height: 100% !important;
    }
  }
}

.Select-arrow {
  border-color: lighten(
                  desaturate(adjust-hue($primary-text-color, 0), 1.7172),
                  6.6667
  ) transparent transparent;
}

.time-select {
  .Select-value-label {
    &:before {
      content: "\003C";
      padding-right: 3px;
    }
  }
}

.map-status-select {
  width: 175px !important;

  .status-option {
    background: none;
    color: black;
    &:before {
      content: "";
      display: inline-block;
      position: relative;
      bottom: -2px;
      width: 15px;
      height: 15px;
      border: 2px solid white;
      border-radius: 20px;
      box-shadow: 0px 1px 1px 0.5px rgba(0, 0, 0, 0.3);
      margin-right: 5px;
    }
    &.green:before {
      background: #42bb4d;
    }
    &.yellow:before {
      background: #ffe325;
    }
    &.orange:before {
      background: #ff942b;
    }
    &.red:before {
      background: #f62525;
    }
    &.black:before {
      background: #2b2b2b;
    }
    &.none:before {
      display: none;
    }
  }
}

.form-horizontal {
  input[type="text"],
  input[type="tel"] {
    border: 1px solid lighten(saturate(adjust-hue($gray-color, -6.8571), 0.3449), 4.3137);
    font-size: 16px !important;
    line-height: 22px;
    color: $primary-text-color;
    font-size: $font-size-default;
    padding: 7px 12px;
    border-radius: 2px;
    outline: none;
    margin: 0 0 10px;
    width: 300px;
    height: 43px;
    border-radius: 8px;
    box-shadow: 0 0 18px 0.12px rgba(41, 94, 255, 0.05);

    @include placeholder {
      color: $disabled-text-color;
    }

    &:focus {
      color: $primary-text-color;
      border-color: $blue-color;
    }
  }
  .primary {
    input[type="text"],
    input[type="tel"] {
      padding-right: 65px;
    }
  }
  span.note + input[type="text"],
  span.note + input[type="tel"] {
    padding-right: 65px;
  }
  .Select {
    margin: 0 0 10px;
  }
  &.company-align{
    margin: 0 !important;
  .Select {
    margin: 0 0px 10px -8px;
    width: 310px;
  }
  .Select-menu{
    height: 100%;
  }
  .Select-menu-outer{
      width: 304px;
      margin-left: 6px;
  }
  }
  .has-error {
    input[type="text"] {
      border-color: $alarm-color;
    }
  }
  .form-group {
    @include clearfix();
    padding: 0 0 0 130px;
    position: relative;

    label {
      position: absolute;
      top: 11px;
      left: 0;
      max-width: 140px;
      font-size: 16px;
      line-height: 22px;
      font-weight: $fw-bold;
      color: #070f29;
    }
  }
  .cust-cb {
    @include clearfix();
    float: none;
    padding: 0 0 1rem 130px;
    margin: 0;
  }

  .input-hold {
    position: relative;

    .remove {
      @include hide-text();
      position: absolute;
      right: 0;
      top: 0;
      width: 34px;
      height: 34px;
      background: url("images/icon-sprites.png") no-repeat -96px -119px;
    }
    .note {
      position: absolute;
      top: 11px;
      right: 10px;
      font-size: $font-size-xs;
      color: $disabled-text-color;
    }
  }
  .has-error {
    input[type="text"] {
      border-color: $alarm-color;
    }
    .error-message {
      color: $alarm-color;
      font-size: $font-size-xs;
      position: absolute;
      bottom: 100%;
      right: 35px;
    }

    .input-hold {
      margin-top: 6px;
    }

    &:first-child {
      .input-hold {
        margin-top: 0;
      }
    }
  }

  .cust-rb {
    position: relative;
    margin-top: 12px;

    label {
      position: static;
      max-width: none;
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
    }
  }
}

.login-form {
  width: 350px;
  margin: auto;
  &.sec {
    width: 300px;
    input[type="text"],
    input[type="password"],
    .btn {
      max-width: 300px;
    }
    input[type="text"] {
      margin-bottom: 30px;
    }
  }
  fieldset {
    display: block;
    width: 100%;
    vertical-align: middle;
    position: relative;
    border: none;
    border-radius: 5px;
    padding: 0;
  }

  h2 {
    text-align: center;
    margin: 0 0 22px;
    font-size: $font-size-h2;
    font-weight: $fw-semi-bold;
  }

  label {
    display: block;
    text-align: center;
    margin: 0 0 18px;
  }

  .field {
    position: relative;

    span.error {
      color: $alarm-color;
      font-size: $font-size-xs;
      text-align: right;
      display: block;
    }
    &.new{
    padding-bottom: 10px;}
  }

  input[type="text"],
  input[type="password"] {
    height: 60px;
    width: 300px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 12px 18px 0 rgba(41, 94, 255, 0.05);
    border: 0px solid lighten(saturate(adjust-hue($gray-color, -5.3571), 20.9101), 3.7255);
    color: #9EA5BD;
    font-size: $font-size-default;
    line-height: $line-height-default;
    padding: 9px 10px 9px 11px;
    // border-radius: 5px;
    outline: none;
    margin: 0 0 20px;
    // max-width: 250px;
    // width:100%;
    // height: 45px;
    // box-shadow:0px 1px 4px 1px #00000024;
    box-shadow: 0 12px 18px 0 rgba(41, 94, 255, 0.05);
    // background: $white-color url("images/icon-mail.png") no-repeat 9px 13px;

    @include placeholder {
      color: #9ea5bd;
      font-family: "Open Sans";
      font-size: 20px;
      letter-spacing: 0;
      // color: $disabled-text-color;
    }

    &:focus {
      color: $primary-text-color;
      border-color: $blue-color;
    }

    &:-webkit-autofill {
      padding: 9px 10px;
    }
  }
  input[type="password"] {
    // background-image: url("images/icon-password.png");
  }

  .forgot-link {
    text-align: right;
    // font-size: $font-size-xs;
    margin: -15px 8.54px 30px;
    position: relative;
    color: #EAAA02;
    font-family: "Open Sans";
    font-size: 16px;
    padding-left: 25px;
  }

  .terms-conditions {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 22px !important;
    font-weight: normal !important;
  }

  .btn {
    height: 60px;
    width: 300px;
    border-radius: 12px;
    background-color: #002D72;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.4);
    // display: block;
    // max-width: 250px;
    // width:100%;
    // padding: 13px;
    // background-color:#007680;
    // border-radius: 5px;
    // color: #ffffff;
    // margin-left: 0%;
    // box-shadow: 0px 1px 3px 1px #337ab7;
  }

  .arrow-right:after {
    content: "";
    display: inline-block !important;
    width: 0;
    height: 0;
    border-left: 4px solid #fff;
    border-top: 6px solid transparent;
    // border-bottom: 5px solid transparent;
    vertical-align: middle;
    // padding-bottom: 2px !important;
  }
}
.sec input[type="text"], .login-form input[type="password"] {
  padding: 9px 10px 9px 16px !important;
}

.arrow-right:before {
  width: 7px;
  height: 2.2px;
  background: $white-color;
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 2px !important;
}

.btn-hold {
  @include clearfix();

  .bck {
    width: 120px;
    padding: 16px 11px;
    margin-left: 9px;
    font-size: 20px;
    font-family: "Open Sans";
    color: #595b60;
    background-color: #dfdfdf;
    border-radius: 12px;
    height: 60px !important;

    &:first-child {
      margin-left: 0;
    }
  }
}

.btn-hold {
  @include clearfix();

  .bc {
    width: 176px;
    height:60px;
    padding: 16px 11px;
    margin-left: 9px;
    font-size: 20px;
    font-family: "Open Sans";
    color: #595b60;
    background-color: #dfdfdf;
    border-radius: 12px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.btn-hold {
  @include clearfix();

  .btn {
    width: 150px;
    height: 60px;
    // opacity: 0.5;
    /* text-align: center; */
    padding: 13px 0px 13px 33px;
    margin-left: 9px;
    font-size: 20px;
    text-align: center;
    /* line-height: 27px; */
    font-family: "Open Sans";
    &:first-child {
      margin-left: 0;
    }
    .btn:not(:disabled):not(.disabled) {
      cursor: pointer;
      box-shadow: 0 10px 20px 0 rgba(76, 147, 255, 0.4) !important;
    }
    .btn:not(:disabled):not(.disabled):hover {
      color: white !important;
    }

  }
}

.Select--single > .Select-control .Select-value,
.Select-placeholder {
  padding-right: 25px;
}

.Select-placeholder {
  padding-right: 25px;
  line-height: 25px !important;
}

.Select-value {
  span {
    line-height: 38px !important;
  }
}

.form-area {
  margin: 0 -20px;
  padding: 15px 30px;
  // border-top: 1px solid $gray-color;

  h3 {
    padding-bottom: 15px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #070f29;
    margin-top: 20px;
  }

  .form-group {
    margin-left: -180px;
  }
  .Select-control {
    width: 100%;
  }
}

.chat-form {
  position: relative;
  height: 73px;
  overflow: hidden;

  textarea {
    width: 100%;
    height: 75px;
    overflow: auto;
    resize: none;
    outline: none;
    font-size: $font-size-s;
    line-height: $line-height-l;
    color: $primary-text-color;
    border: none;
    padding: 10px 70px 10px 15px;
    margin: 0;
  }
  button {
    @include hide-text();
    position: absolute;
    top: 50%;
    right: 16px;
    width: 25px;
    height: 25px;
    border: none;
    margin: -12px 0 0;
    padding: 0;
    outline: none;
    background: url("images/icon-sprites.png") no-repeat -2px -85px;
  }
}

.password-criteria {
  position: relative;
  z-index: 1000;
  .password-popup {
    position: absolute;
    top: 0;
    left: 103%;
    background: $white-color;
    border: 1px solid $gray-color;
    box-shadow: $popup-shadow;
    width: 275px;
    border-radius: 4px;
    padding: 10px 15px 15px;

    &:after,
    &:before {
      right: 100%;
      top: 17px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-right-color: $white-color;
      border-width: 5px;
      margin-top: -5px;
    }
    &:before {
      border-right-color: $gray-color;
      border-width: 6px;
      margin-top: -6px;
    }
    h5 {
      margin: 0 0 6px;
      font-size: $font-size-xs;
      font-weight: $fw-regular;
      color: $primary-text-color;
    }

    ul {
      @include reset-list();
      font-size: $font-size-xs;
      line-height: $line-height-l;

      li {
        float:left;
        padding-left: 17px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 3px;
          left: 0;
          width: 13px;
          height: 11px;
          background: url("images/icon-validation.png") no-repeat;
        }
      }
    }
    .valid {
      color: $green-color;
    }
    .invalid {
      color: $alarm-color;

      &:before {
        background-position: 0 -17px;
      }
    }
  }
}

.accounts-form-holder {
  .side-block {
    width: 600px !important;
  }
  .agency-contact {
    // border-top: 1px solid $gray-color;
    //padding-top: 10px;
    margin: 0;
    position: relative;
    .form-group {
      display: inline-block;
      padding: 0 10px 0 0;
    }
    .form-control {
      margin: 0;
    }
    .control-label {
      display: none;
    }
    .error-message {
      left: 0;
      top: 100% !important;
    }
    .accounts-contact-delete {
      //position: absolute;
      //right: 0;
      //padding: 5px;
      //margin-top: 2px;
    }
  }
  .agency-input-block {
    display: flex;
  }
}

.backimage {
  background: url("images/Airplane.jpg") no-repeat;
  height: 100vh;
  margin: 0;
  display: flex;
  background-size: cover;
}

.backimage h1 {
  // color: $white-color;
  // text-decoration: underline;
  // position: absolute;
  // bottom: 49px;
  // left: 16px;
  // font-size: 40px;
  // font-weight: bold;
  height: 30px;
  width: 292px;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  bottom: 100px;
  left: 39.5px;
}

.backimage h1:before {
  content: "";
  position: absolute;
  width: 53%;
  height: 1px;
  bottom: -31.5px;
  // left: 16px;
  border-bottom: 6px solid $white-color;
  font-weight: bold;
  padding-bottom: 2%;
  left: 0px;
}

.logo {
  background: url("images/WT_WorldWatch2024.png") no-repeat;
  background-size: contain;
  height: 150px;
  margin: 45px auto;
  background-position: center;
  //width: 500px;
  &.new{
    margin: 45px auto 100px auto;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1284px;
  }
}

@media only screen and (max-width: 100%) {
  .container {
    max-width: 100%;
  }
}

.logoh {
  height: 100vh;
  background-color: #f1f2f4;
}

.fa_custom {
  float: right;
  // padding-top: 1%;
  padding-right: 42px;
  padding-top: 2px;
  color: white;
}

.btn0:hover {
  color: white !important;
}

//.container {
//  margin-left: 0% !important;
//}

.justi-content {
  padding-left: 8%;
  // margin-top: 2%;
}

.rowClass {
  margin-left: -30px !important;
}

.forgot-form {
  background: $white-color;
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arr-cls {
  // border: 1px solid #007680;
  border-radius: 50%;
  // font-size: 10px;
  background: #007680;
  // padding: 3px;
  // padding-left: 27%;
  // padding-right: 29%;
  // padding-top: 13%;
  // padding-bottom: 28%;
  // margin-left: -248%;
  height: 24px;
  width: 24px;
  display: flex;
  // padding-top: 1px !important;
  // font-size: small !important;
  text-align: center;
  justify-content: center;
  align-items: center;
  &.arrow-align {
    position: absolute;
    bottom: 16px;
    right: 40px;
    font-size: medium;
  }
}

.loginText {
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  padding-left: 45px;
}

.head {
  color: #6f7b94;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.message {
  color: #6f7b94;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: start;
}

.msg2 {
  padding: 25px 0;
}

.field1 input[type="text"] {
  height: 60px !important;
  font-family: "Open Sans", sans-serif !important;
  max-width: 300px;
  border: 0px solid #dde3ed !important;
  color: #9ea5bd !important;
  font-size: 20px !important;
  border-radius: 12px !important;
  outline: none !important;
  box-shadow: 0 12px 18px 0 rgba(41, 94, 255, 0.05) !important;
  padding: 9px 10px 9px 11px !important;
  margin-bottom: 50px !important;
}

.btn-flex {
  // display: flex !important;
  justify-content: center;
  div {
    margin: 0 16px;
  }
}

.btn-user-disabled {
  background: #DFDFDF;
  border-radius: 13px;
  border: 1px solid #dde3ed;
  min-width: 160px;
  height: 50px;
  padding: 9px;
  font-size: 20px;
  color: #595B60;
  line-height: 24px;
  &.poi{
    padding: 16px 0 !important;
    font-weight: 400;
  }
  &.db{
    padding: 16px 0 0px 0 !important;
    text-align: center;
    height: 60px !important;
  }
}

.btn-add-user {
  background-color: #e0e1e3 !important;
  color: #8D8F97 !important;
  cursor: default;
  width: 240px;
  height: 50px;
  border-radius: 12px;
  background: #E0E1E3;
  box-shadow: none;
  font-size: 20px;
  line-height: 24px;
}

.btn-user-disabled {
  background: #dfdfdf;
  border-radius: 13px;
  border: 1px solid #dde3ed;
  min-width: 160px;
  height: 50px;
  padding: 9px;
  font-size: 20px;
  color: #595b60;
  line-height: 24px;
}

.btn-add-user {
  background-color: #e0e1e3 !important;
  color: #8d8f97 !important;
  cursor: default;
  width: 240px;
  height: 50px;
  border-radius: 12px;
  background: #e0e1e3;
  box-shadow: none;
  font-size: 20px;
  line-height: 24px;
}

.btn-add-user:not(:disabled):not(.disabled) {
  background-color: #007680 !important;
  box-shadow: 0 10px 20px 0 rgba(76, 147, 255, 0.4);
  font-size: 20px;
  line-height: 24px;
  color: #ffffff !important;
}

.bg-red {
  background-color: red;
}

.bg-green {
  background-color: green;
}

.company-head {
  color: #070f29;
  font-size: 20px;
  font-weight: $fw-bold;
  letter-spacing: 0;
  line-height: 27px;
  padding-left: 12px;
}

.table .sortable .travelers-table:after {
  border-top-color: #b7bdc9;
  top: 9px;
}

.import-btn {
  height: 60px;
  width: 240px;
  border-radius: 12px;
  background-color: #e0e1e3;
  color: #8d8f97;
  font-family: Rubik;
  font-size: 20px !important;
  letter-spacing: 0;
  line-height: 2.2rem;
  text-align: center;
  padding-top: 12px;
  pointer-events: none;
  &:hover {
    color:#ffffff;
  }
}
.imp-button{
  height: 60px;
  width: 240px;
  border-radius: 12px;
  background-color: #007680;
  box-shadow: 0 10px 20px 0 rgba(76,147,255,0.4);
  color:#ffffff;
  padding-top: 14px;
  font-size: 20px;
  &:hover {
    color:#ffffff;
  }
}

.fa_customm {
  float: right;
  padding-right: 53px;
  padding-top: 3px;
  position: relative;
}

.field1 input[type="text"]::placeholder {
  font-size: 20px !important;
}

.account-form-class {
  padding: 10px 15px !important;
}

.block-logo-header {
  width: 22px;
  height: 24px;
  margin: 0px 4px;
  vertical-align: top;
}

.block-icon {
  &.bg{
  position: absolute;
  bottom: 2px;
  margin: 0;
  }
  &.rg{
  position: absolute;
  bottom: 0px;
  margin: 0;
  }
  margin-right: 16px;
}
.text-class{
margin-left: 36px;
}
.teg-container {
  display: flex;
  width: 600px;
  min-height: 40px;
  flex-wrap: wrap;
}

.import-page {
  &.page-content {
    padding: 25px 0px 0px 45px !important;
    .head-row {
      padding-bottom: 55px;
    }
  }
}

.company {
  .Select-control {
    width: 200px !important;
    height: 27px !important;
    font-size: 13px !important;
    color: #595b60 !important;
    font-family: "Open Sans" !important;
    //display: flex;
    justify-content: right;
    align-items: center;
    border: 1px solid #AAAAAA;
  }
  .Select-placeholder {
    //height: 27px !important;
    color: #595B60;
    display: flex !important;
    align-items: center !important;
  }
}

.healths {
  // padding-bottom: 15px;
  .Select-control {
    width: 200px !important;
    height: 27px !important;
    font-size: 13px !important;
    color: #595b60 !important;
    font-family: "Open Sans" !important;
    border-radius: 4px !important;
    //display: flex;
    justify-content: right;
    align-items: center;
    // padding-bottom: 17px;
  }
  .Select-placeholder {
    //height: 27px !important;
    display: flex !important;
    align-items: center !important;
  }
}

.security {
  // padding-bottom: 15px;
  .Select-control {
    width: 200px !important;
    height: 27px !important;
    font-size: 13px !important;
    color: #595b60 !important;
    font-family: "Open Sans" !important;
    //display: flex;
    justify-content: right;
    border-radius: 4px !important;
    align-items: center;
    // padding-bottom: 17px;
  }
  .Select-placeholder {
    //height: 27px !important;
    display: flex !important;
    align-items: center !important;
  }
}

.interest {
  // padding-bottom: 15px;
  .Select-control {
    width: 200px !important;
    height: 27px !important;
    font-size: 13px !important;
    color: #595b60 !important;
    font-family: "Open Sans" !important;
    //display: flex;
    justify-content: right;
    border-radius: 4px !important;
    align-items: center;
  }
  .Select-placeholder {
    //height: 27px !important;
    display: flex !important;
    align-items: center !important;

  }
}

.error-span {
  margin-left: 48px;
  position: absolute;
  top: 10px;
  span,
  label {
    font-size: 16px;
    line-height: 22px;
  }
}

.add-user-form {
  input[type="text"] {
    margin: 0 3rem 10px;
  }
  .cust-rb {
    margin-left: 3rem;
    label {
      color: #33333b;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
    }
  }
  .form-group {
    label {
      max-width: 175px;
    }

    .error-message {
      right: -40px !important;
    }
  }
}

.continent-group{
  .is-focused > .Select-control .fa-angle-down::before, .is-searchable.is-focused:not(.is-open) > .Select-control .fa-angle-down::before {
    color: #007680 !important;
    position: absolute;
    top: 0;
    left: 0;
}
//   .Select, .Select div, .Select input, .Select span {
//     box-sizing: border-box;
//     height: 100%;
//     overflow: unset;
//     z-index: 9999;
// }
.Select, .Select div, .Select input, .Select span {
    box-sizing: border-box;
    overflow: unset;
}
.Select-option {
    box-sizing: border-box;
    background-color: #fff;
    color: #666666;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
    border-left: 2px solid #007680;
    border-right: 2px solid #007680;
}
.Select-option:last-child{
  border-bottom: 2px solid #007680;
}

.Select-option.is-focused {
    background-color: #ebf5ff;
    // background-color: rgba(0, 126, 255, 0.08);
    color: #333;
}
  .Select-control{
    width:300px;
    margin-left:42px;
    border: none;
  }
  .Select-placeholder {
    padding: 8px 0 0 12px;
    /* line-height: 25px !important; */
}
  .Select-menu-outer {
    width: 300px;
    margin-left: 48px;
    input[type="checkbox"] {
      &:not(:checked),
      &:checked {
        position: absolute;
        left: -9999px;
      }

      &:not(:checked) + label,
      &:checked + label {
        position: relative;
        padding-left: 25px;
        cursor: pointer;
      }

      &:not(:checked) + label:before,
      &:checked + label:before {
        display: none;
      }

      &:not(:checked) + label:after,
      &:checked + label:after {
        display: none;
      }
    }
  }
  .fa-angle-down:before {
    content: "\f107";
    color: #aaaaaa;
    position: absolute;
    top: 12px;
    right: 19px;
  }
  .Select.has-value.Select--single
  > .Select-control
  .Select-value
  .Select-value-label,
  .Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
    color: #33333b;
    font-family: "Open Sans";
    font-size: 16px;
    margin: 8px 2px;
  }
}

.permissions-select {
  .Select {
    margin: 0 0px 10px -10px;
  }
    .Select-placeholder {
    padding: 8px 0 0 12px;
  }
  .Select-control{
    width: 475px;
    border: none !important;
  }
  .Select-menu-outer{
    width: 475px !important;
    margin-left: 6px;
  }
  .fa-angle-down:before {
    content: "\f107";
    color: #aaaaaa;
    position: absolute;
    top: 12px;
    right: 19px;
  }
  .Select.has-value.Select--single
  > .Select-control
  .Select-value
  .Select-value-label,
  .Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
    color: #33333b;
    font-family: "Open Sans";
    font-size: 16px;
    margin: 8px 0;
  }
}

.bottom-block {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 140px;
}

.add-phone-class {
  a {
    color: #288fff !important;
    font-size: 16px !important;
    text-decoration: none !important;
  }
}

.travelers-form-group {
  .form-group {
    padding: 0 0 0 160px !important;
    label {
      min-width: 150px !important;
    }
  }
  .cust-cb {
    padding: 0 0 1rem 160px !important;
    label {
      font-size: 16px;
    }
  }
  .btnn1 {
    height: 60px;
    width: 150px;
    // opacity: 0.5;
    border-radius: 12px;
  }

  .fa_customm {
    float: right !important;
    padding-right: 5% !important;
    padding-top: 3px !important;
    position: relative;
  }
  .field1 input[type="text"]::placeholder {
    font-size: 20px !important;
  }
}

.account-form-class {
  padding: 10px 15px !important;
}

.block-logo-header {
  width: 22px;
  height: 24px;
  margin: 0px 4px;

  vertical-align: top;
}

.block-icon {
  margin-right: 16px;
}

.teg-container {
  display: flex;
  width: 600px;
  min-height: 40px;
  flex-wrap: wrap;
}

.import-page {
  &.page-content {
    padding: 25px 0px 0px 45px !important;
    .head-row {
      padding-bottom: 55px;
    }
  }
}

.company {
  .Select-control {
    width: 180px !important;
    height: 27px !important;
    font-size: 13px !important;
    color: #595b60 !important;
    font-family: "Open Sans" !important;
    //display: flex;
    justify-content: right;
    align-items: center;
    border: 1px solid #aaaaaa;
  }
  .Select-placeholder {
    //height: 27px !important;
    display: flex !important;
    align-items: center !important;
  }
}

.healths {
  // padding-bottom: 15px;
  .Select-control {
    width: 200px !important;
    height: 27px !important;
    font-size: 13px !important;
    color: #595b60 !important;
    font-family: "Open Sans" !important;
    border-radius: 4px !important;
    //display: flex;
    justify-content: right;
    align-items: center;
    // padding-bottom: 17px;
  }
  .Select-placeholder {
    //height: 27px !important;
    display: flex !important;
    align-items: center !important;
  }
}

.security {
  // padding-bottom: 15px;
  .Select-control {
    width: 200px !important;
    height: 27px !important;
    font-size: 13px !important;
    color: #595b60 !important;
    font-family: "Open Sans" !important;
    //display: flex;
    justify-content: right;
    border-radius: 4px !important;
    align-items: center;
    // padding-bottom: 17px;
  }
  .Select-placeholder {
    //height: 27px !important;
    display: flex !important;
    align-items: center !important;
  }
}

.interest {
  // padding-bottom: 15px;
  .Select-control {
    width: 200px !important;
    height: 27px !important;
    font-size: 13px !important;
    color: #595b60 !important;
    font-family: "Open Sans" !important;
    //display: flex;
    justify-content: right;
    border-radius: 4px !important;
    align-items: center;
  }
  .Select-placeholder {
    //height: 27px !important;
    display: flex !important;
    align-items: center !important;
  }
}

.import-body-selected {
  box-sizing: border-box;
  height: 79.04px;
  width: 500px;
  border: 1.32px dashed #007680;
  border-radius: 2.63px;
  margin: 40px 50px;
  display: flex;
  img {
    width: 72px;
    height: 48px;
    position: absolute;
    top: 110px;
    left: 60px;
  }
}

.office-span {
  label {
    margin-left: 15px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: #070f29;
  }
  .office-text-area {
    overflow: auto;
    border: 1px solid #e4e6e9;
    font-size: 16px !important;
    line-height: 22px;
    color: #33333b;
    font-size: 14px;
    padding: 7px 10px;
    border-radius: 2px;
    outline: none;
    margin: -32px 180px 20px;
    width: 300px;
    height: 100px;
    border-radius: 8px;
    box-shadow: 0 12px 18px 0 rgba(41, 94, 255, 0.05);
  }
}

.input-block {
  &.office-block {
    .form-group::after {
      right: 200px !important;
    }
  }
  .form-group::after {
    visibility: visible !important;
    position: absolute;
    top: 8px;
    right: 12px;
    width: 24px;
    height: 30px;
    border: 0;
    padding: 0;
    overflow: hidden;
    background: url("../styles/images/Block.svg") no-repeat;
    cursor: pointer;
  }
}

.label-cnt {
  &.label-cmt {
    margin-left: 15px;
  }
}

.cust-cb {
  &.poi-cb {
    padding: 0 0 1rem 25px;
  }
}

.flex-container {
  display: flex;
}

.import-group {
  display: flex;
  img{
    padding-right: 8px;
  }
  span {
    padding: 20px 0 0 20px;
    width: 252px;
    font-size: 16px;
  }
}

.import-container {
  .filter-head .opener:after {
    visibility: hidden;
  }
}

.import-card {
  width: 600px;
  height: 310px;
  position: absolute;
  left: 420px;
  top: 240px;
  border-radius: 14px;
  background-color: #fdfefe;
  box-shadow: 12px 8px 44px -12px rgba(27, 58, 146, 0.16);
  span {
    font-family: "Open Sans";
    font-size: 14px;
    padding: 0;
    position: absolute;
    right: -80px;
    bottom: 114px;
    opacity: 0.5;
    color: #33333b;
  }
}

.import-head {
  height: 32px;
  width: 501px;
  color: #070f29;
  font-family: "Open Sans";
  font-size: 23.28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin: 25px 56px;
}

.import-body {
  box-sizing: border-box;
  height: 79.04px;
  width: 500px;
  border: 1.32px dashed #cccccc;
  border-radius: 2.63px;
  margin: 40px 50px;
  display: flex;
  img {
    width: 72px;
    height: 48px;
    position: absolute;
    top: 110px;
    left: 60px;
  }
}

.import-content {
  display: flex;
  flex-direction: column;
  color: #33333b;
  font-family: "Open Sans";
  padding: 12px 0 0 100px;
  h1 {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  p {
    font-size: 13.17px;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.arr-position {
  position: absolute;
  top: 66px;
  left: 38px;
}

.btn-style {
  padding: 10px 50px;
}

.holder-child {
  display: flex;
  justify-content: space-between;
}

.additional-child {
  width: 200px;
  height: 27px;
}

.dsb-style-1 {
  background-color: #007680;
  color: #ffffff;
}

.import-content {
  display: flex;
  flex-direction: column;
  color: #33333b;
  font-family: "Open Sans";
  padding: 12px 0 0 100px;
  h1 {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  p {
    font-size: 13.17px;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.arr-position {
  position: absolute;
  top: 66px;
  left: 38px;
}

.btn-style {
  padding: 10px 50px;
}

.holder-child {
  display: flex;
  justify-content: space-between;
}

.additional-child {
  width: 200px;
  height: 27px;
}

.dsb-style-1 {
  background-color: #007680;
  color: #ffffff;
}

.dsb-style-2 {
  background-color: #dfdfdf;
}

.icon-style-1 {
  background-color: #007680;
  color: white;
}

.icon-style-2 {
  background-color: #bbbbbb;
  color: black;
}

.buttons-r-align {
  padding-right: 55px;
}

.disagree-group {
  padding-right: 24px;
}

.poi-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field {
  input:focus ~ .floating-label,
  input:not(:focus):valid ~ .floating-label {
    top: 8px !important;
    bottom: 10px !important;
    left: 20px !important;
    font-size: 12px !important;
    opacity: 1 !important;
    font-family: "Open Sans" !important;
  }

  #name,
  #password {
    //  padding-top: 20px !important;
    padding-left: 20px !important;
  }
  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 20px !important;
    top: 18px;
    transition: 0.2s ease all !important;
    font-size: 20px !important;
    color: #9ea5bd !important;
    font-family: "Open Sans" !important;
  }
}

.poi-table-width {
  width: 100% !important;
}

.poi-icon{
  padding-right: 0.5rem;
}
.poi-edit-link{
  text-decoration: underline;
}

.poi-head-align{
padding-left: 5px;
}
.upload-status-table {
  margin-left: -45px;
}

.Popover {
  .Popover-body {
    border: none;
  }
  .Popover-tipShape {
    fill: #ffffff;
  }
  ul {
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
  }
}

.public_Scrollbar_face:after {
  background-color: #d8d8d8;
}

.import-buttons-container {
  &.upload-btn-container {
    margin-left: 27px !important;
    margin-bottom: 25px;
  }
}

.public_Scrollbar_main {
  background-color: inherit !important;
  border: none !important;
}

.upload-progress,
.upload-progress-green {
  height: 58px;
  width: 475px;
  border-radius: 11px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.upload-progress {
  background-color: #33333b !important;
}

.upload-progress-green {
  background-color: #4cc07b !important;
}

.upload-file-details {
  margin-left: 10px;
  p {
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
  }
}

.file-size {
  opacity: 0.6;
  font-size: 13px !important;
}

.upload-file-progress {
  margin-right: 45px;
  p {
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
  }
  .on-cancel {
    cursor: pointer;
  }
  .on-cancel-align {
    padding-left: 25px;
  }
}

.file-close {
  left: 455px !important;
  top: 113px !important;
}

.loading-container {
  position: absolute;
  right: 80px;
  top: 115px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #595b60;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 3px solid #fdfefe;
  border-radius: 50%;
  border-top: 3px solid #595b60;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-err {
  // width:100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
    width: 367px;
    margin: auto;
}

.btn-error {
    height: 67px;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background-color: #F25151;
    box-shadow: 12px 8px 44px -12px rgba(27, 58, 146, 0.12);
    padding: 20px 0 ;
  // height: 67px;
  // width: 367px;
  // border-radius: 12px;
  // background-color: #F25151;
  // box-shadow: 12px 8px 44px -12px rgba(27, 58, 146, 0.12);
  // padding-top: 23px !important;
  &.bx{
    margin-top: 40px;
    position: relative;
  }

  .loginErrText {
    padding: 0 30px 0 45px;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 20px;
    text-align-last: left;
    align-self: center;
    //padding-left: 6px !important;
    //float: left !important;
    // &.tx{
    //   position: absolute;
    //   left: -40px;
    //   top: 8px;
    // }
  }
  .fa_cust {
    float: right;
    color: white;
    padding-right: 22px;
    padding-top: 3px !important;
    align-self: center;
    .fa-times {
      // font-style: 550 !important;
      font-weight: 700 !important;
    }
    &.fx{
      position: absolute;
      right: 0px;
      top: 20px;
    }
  }
}

.user-filter, .travelers-search-block {
  .search-block input[name='search'] {
    width: 292px;
    height: 28px;
  }
  .search-block input[name='search']::placeholder {
    height: 17px;
    width: 91px;
    color: #DBDBDB;
    font-family: Rubik;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }
}

.inner-table {
  &.organization-user-table {
    th {
      padding: 10px 15px 0 !important;
    }
  }
  th {
    padding: 10px 10px 0 !important;
    &.align-id {
      padding-left: 15px !important;
    }
    &.align-action {
      padding-left: 25px !important;
    }
  }
  td, td:hover {
    border: none !important;
  }
}

.arr-align {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 18px;
  right: 42px;
}

.fa_customm {
  position: relative;
  .forget-arr-align {
    border: 0;
    width: 25px;
    position: absolute;
    left: 15px;
    top: 3px;
  }
}
.cmp-select{
   .Select-placeholder {
    padding: 8px 0 0 12px;
}
.Select--single > .Select-control .Select-value{
     padding-top: 3px;
}}
.sort-select{
  .Select--single > .Select-control .Select-value{
    padding-top: 3px;
  }
  .Select-placeholder {
     padding-top: 8px;
}
}
.password-title{
  color: #6F7B94;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 25px;
}
.closeIco{
  position: absolute;
  right: 0;
  top:20px;
  cursor: pointer;
}
.notes-section{
  width: 300px;
  height: 120px !important;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 12px 18px 0 rgba(41,94,255,0.05);
}
.set-disable,.set-submit{
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  position: relative;
}
.set-disable{
   color: #595B60;
}
.set-submit{
  color: #FFFFFF;
  font-family: "Open Sans";
}
.arrow-fix{
    position: absolute;
    width: 24px;
    right: 42px;
    top: 20px;
}
.company-user-table{
    max-width: 800px !important;
}
.row-wise{
  display: flex;
  padding-bottom: 25px;
  justify-content: space-between;
}
.orgType{
  .Select-menu-outer{
    width: 112px;
    margin-left: 6px;
    border-bottom: 2px solid #007680;
    border-left: 2px solid #007680;
    border-right: 2px solid #007680;
  }
  .Select-menu{
    height: 100% !important;
  }
}
.assctd-agency{
  .Select-menu-outer{
    width: 234px;
    margin-left: 6px;
  }
}
.user-contact{
  padding-left: 12px;
  margin-bottom: 25px;
}
.breadcrumb{
  &.pdg15{
    padding-left: 15px;
  }
}
.fm{
  .has-error .error-message {
    right: 0 !important;
  }
}
.add-form{
  .form-group .error-message {
    right: -14px !important;
}
}

.acc-heading{
  color: #070F29;
  font-family: "Open Sans";
  font-size: 30px;
  font-weight: bold;
}
