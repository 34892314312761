.draggable-filter {
    .filter-inner-container {
        .filter-header {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding: 10px 10px 0px 10px;
                .filter-heading p{
                    margin: 0;
                }

            .filter-header-right {
                float: right;
            }
        }
        .filter-close1{
            margin-bottom: 12px;
            cursor: pointer;
        }
        .filter-drag{
            margin-bottom:8px;
        }

        .filter-dateranges-container {
            // padding: 30px 6px 0px 6px !important;

            .date-range-title {
                color         : #007680;
                font-family   : "Open Sans";
                font-size     : 16px;
                font-weight   : bold;
                letter-spacing: 0.4px;
                line-height   : 16px;
                padding-left: 10px !important;
            }

            p {
                padding: unset !important;
            }

            .date-range {
                padding-top   : 12px;
                padding-bottom: 22px;
                padding-left: 10px;
                .filter-dateranges {
                    padding: unset !important;

                    label {
                            margin-right: 8px;
                            padding: 0px 0px 0 0;
                            color: #595B60;
                            font-weight: 400;
                            font-size: 13px;
                    }

                    .react-datepicker__tab-loop {
                        position: absolute;
                        top     : 0px;
                    }

                    .filter-daterange {
                        margin-right: 0px !important;
                        width       : 80px
                    }
                }
            }
        }
    }

    .select-holder {
        .Select {
            width         : 100%;
            padding-right : 12px;
            margin        : unset;
            float         : unset;
            padding-bottom: 0px !important;

            .Select-control {
                margin-bottom: unset;
                width        : 100%;
            }
        }
    }

    .disruption-status-container {
        padding   : 13px 6px 22px 0px;
        p{
            color: #007680 !important;
            font-family: "Open Sans" !important;
            font-size: 16px !important;
            font-weight: bold !important;
            letter-spacing: 0.4px !important;
            line-height: 16px !important;
            padding-left: 10px;
        }
        // border-top: 1px solid #DFDFDF;
        .Select, .Select div, .Select input, .Select span {
            box-sizing: border-box;
            overflow: unset;
            font-weight: 400;
        }
    }

    .additional-filters {
        padding: 12px 0px 0px 12px;
        // border-top: 1px solid #DFDFDF;
    }
}
.filter-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    .filter-heading p{
        margin: 0;
    }
    .filter-header-right {
        float: right;
    }
    &.export{
        padding: 4px 10px 5px 5px;
    }
    &.tr-filter{
        padding: 5px 0px 5px 10px;
    }
}
.filter-line1{
    border-top: 1px solid #595B60;
    opacity: 0.4;
}
.filter-close1{
    cursor: pointer;
}
.clear-filter1{
    color: #288FFF;
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    text-align: right;
    padding-bottom: 10px;
    .clr,.clr1{
        cursor: pointer;
        padding: 4px 20px 20px 0px;
        float: right;
        text-decoration: underline;
    }
    .clr1{
        padding:0 0.6rem 0 0 !important;
    }
}
.Select-multi-value-wrapper{
    width: 100% !important;
}
.disruption-select{

    .Select-control {
        background-color: #fff;
        // border-color: #d9d9d9 #ccc #b3b3b3;
        border: 1px solid #AAAAAA !important;
        // border-radius: 4px;
         border-top-right-radius: 4px !important;
        border-top-left-radius: 4px !important;
        color: #333;
        cursor: default;
        display: flex !important;
        border-spacing: 0;
        // border-collapse: separate;
        height: 36px;
        outline: none;
        overflow: hidden;
        position: relative;
        // width: 100%;
        flex-direction: column;
        align-content: end;
        align-items: initial;
        justify-content: end;
        margin-bottom: 0px !important;
    }
    .Select-arrow-zone {
        cursor: pointer;
        position: absolute;
        left: 180px;
        top: 3px;
        text-align: center;
        vertical-align: middle;
        right:0;
        //width: 195px !important;
        // padding-right: 5px;
        //display: flex;
        justify-content: end;
        padding-bottom: 7px;
        padding-right: 14px !important;
    }
    .Select-placeholder {
        padding-right: 25px;
        line-height: 38px !important;
    }
    .Select--single > .Select-control .Select-value, .Select-placeholder {
        padding-right: 25px;
    }
    .Select-placeholder, .Select--single > .Select-control .Select-value {
        bottom: 0px;
        color: #aaa;
        left: 0;
        line-height: 34px;
        font-family: 'Open Sans', sans-serif;
        padding-left: 10px;
        // padding-right: 10px;
        position: absolute;
        right: 0;
        top: 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // text-align: inherit;
        display: flex;
        align-items: center;
    }
    .Select-clear{
        position: absolute !important;
        padding-top: 10px !important;
    }
    .Select-menu-outer{
        // border: 2px solid #007680 !important;
        width: 200px;
        border-bottom:2px solid #007680 !important;
        border-left:2px solid #007680 !important;
        border-right: 2px solid #007680 !important;
        border-top: none !important;
        border-bottom-right-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        margin-left: 6px !important;
        // margin-bottom: 20px !important;
        // display: flex;
        i {
            display: flex !important;
            flex-direction: column !important;
        }
        input[type="checkbox"] {
            &:not(:checked),
            &:checked {
              // position: absolute;
              // left: -9999px;
            }
            &:not(:checked) + label,
            &:checked + label {
              // position: relative;
              // padding-left: 25px;
              cursor: pointer;
              color: #595B60 !important;
              font-family: "Open Sans" !important;
              font-size: 13px !important;
              letter-spacing: 0;
              display: flex;
              flex-direction: column !important;
              // width: 15px;
              height: 15px;
            }

            &:not(:checked) + label:before,
            &:checked + label:before {
              content: '';
              display: flex;
              flex-direction: column !important;
              // position: absolute;
              // left:0;
              // top: 2px;
            //   background-color: $white-color;
            //   border: 1px solid $gray-color;
              border-radius: 1px;
              color: #595B60 !important;
              font-family: "Open Sans" !important;
              font-size: 13px !important;
              letter-spacing: 0;
              // line-height: 23px;
            }

            &:not(:checked) + label:after,
            &:checked + label:after {
              content: '';
              // display: block;
              // position: absolute;
              // left:0;
              // top: 2px;
              width: 15px;
              height: 15px;
              // background: url("images/icon-checkbox.png") no-repeat center center;
            }

            &:not(:checked) + label:after {
              opacity: 0;
            }

            &:checked + label:after {
              opacity: 1;
            }
        }
    }
}

.is-focused > .Select-control, .is-searchable.is-focused:not(.is-open) > .Select-control {
    border-top:2px solid #007680 !important;
    border-left:2px solid #007680 !important;
    border-right: 2px solid #007680 !important;
    border-bottom: none !important;
}


.disruptions{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: #595B60;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 17px;
    display: flex !important;
    align-items: center !important;
    height: 25px !important;
    font-weight: 400;
  }

}

.Dlabel{
    display: flex !important;
    // align-items: center !important;
    // flex-direction: row !important;
    justify-content: center !important;

}

.Dicon{
    display: flex !important;
    flex-direction: row !important;
    padding-left: 10px !important;
    // text-align: center !important;
    align-items: center !important;
    // margin-bottom: 22px !important;
    i{
        width: 16px !important;
        height: 16px !important;
    }
    .Dlabel{
        color: #595B60 !important;
        font-family: "Open Sans" !important;
        font-size: 12px !important;
        letter-spacing: 0 !important;
        // line-height: 23px !important;
        font-weight: normal !important;
        padding-left: 9px !important;
        // margin-bottom: 12px !important;
    }
}
hr {
    display: block;
    width: 100%;
    border: 0;
    border-top: 0.5px solid #F1F2F4;
    margin: 0px;
    // margin: 8px 14px;
  }
// .draggable-filter .select-holder .Select {

//     width: 100%;
//     padding-right: 12px;
//     margin: unset;
//     float: unset;
//     padding-bottom: 0px !important;

// }

.additional{
    &.label-align{
        .Select-value{
            position: absolute;
            top: -6px;
        }
    }
    .Select-control {
        background-color: #fff;
        // border-radius: 4px;
         border-top-right-radius: 4px !important;
        border-top-left-radius: 4px !important;
        border: 1px solid #AAAAAA !important;
        color: #333;
        cursor: default;
        display: flex !important;
        border-spacing: 0;
        // border-collapse: separate;
        // height: 36px;
        font-size: 13px !important;
        font-family: "open sans" !important;
        font-weight: normal !important;
        outline: none;
        overflow: hidden;
        position: relative;
        // width: 100%;
        flex-direction: column;
        align-content: end;
        align-items: initial;
        justify-content: end;
        margin-bottom: 20px !important;
    }
    .Select-menu-outer{
        // border: 2px solid #007680 !important;
        display: flex !important;
        justify-content: center !important;
        width: 200px !important;
        flex-direction: column !important;
        border-bottom:2px solid #007680 !important;
        border-left:2px solid #007680 !important;
        border-right: 2px solid #007680 !important;
        border-top: none !important;
        font-size: 13px !important;
        font-family: "open sans" !important;
        font-weight: normal !important;
        border-bottom-right-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        margin-left: 6px !important;
        // margin-bottom: 10px !important;
        scrollbar-width: thin !important;
      }
      .Select-menu-outer-label{
          display: flex !important;
          align-items: center !important;
      }
    .Select-arrow-zone {
        cursor: pointer;
        position: absolute;
        justify-content: end;
        right: 0;
    }
    .Select-placeholder{
        color:#595B60;
        display: flex !important;
        align-items: center !important;
    }
    .is-focused > .Select-control, .is-searchable.is-focused:not(.is-open) > .Select-control {
        border-top:2px solid #007680 !important;
        border-left:2px solid #007680 !important;
        border-right: 2px solid #007680 !important;
        border-bottom: none !important;
    }

}

.filter-header-left{
    .filter-header-right{
        // padding-right: 12px !important;
    }
    a{
        color: #007680 !important;
    }
}

.is-focused > .Select-control, .is-searchable.is-focused:not(.is-open) > .Select-control {
    // border:2px solid #007680 !important;
    // border-color: 1px solid #AAAAAA !important;
    border-bottom-left-radius: 0px !important ;
    border-bottom-right-radius: 0px !important ;
    .fa, .fas {
      font-weight: 550 !important;
      transform: rotate(180deg);
      color:#007680 !important;
    }
    .fa-angle-down::before {
        color:#007680 !important;
    }
}
.fa-angle-down::before {
    color:#AAAAAA !important;
}
.opener-div{
    padding-right: 15px;
}
.disruption-icon{
  width: 16px;
  &.dis-align{
    position: absolute;
    left: 36px;
    top: -3px;
  }
  &.ico-align{
    position: absolute;
    top: 7px;
    left:30px;
  }
}
.co2-footsteps{
    cursor: pointer;
    margin-right: 1rem
}
.distruptions-block,.company-select-block, .additional-block{
    .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    position: absolute;
    top: -5px;
    }
}
.distruptions-block,.company-select-block, .additional-block{
    .Select-placeholder,.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
        color: #595B60 !important;
    }
}
.distruptions-block{
    .Select-menu{
        height: 100% !important;
    }
    .Select-option{
  span{
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  .Dlabel{
    width: 100%;
    position: absolute;
    right: 2px;
    top: -4px;
  }
  }
}
}
.company-selectt{
    .Select-menu{
        height: 100% !important;
    }
}
.costCenter-selectt{
     .Select-menu span{
        label {
    display: inline-block;
    margin-bottom:0;
    margin-left:4px
}
    }
}
