
.filter-block{
  position: relative;
  .filter-body{
    position: absolute;
    top: 40px;
    left: 20px;
    z-index:9999;
    min-height: 220px;
    width:220px;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-shadow: 0 12px 18px 0 rgba(41,94,255,0.05);
    .filterBlock-head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 2px 0px 10px;
      span{
          color: #595B60;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
      }
    }
    .filter-line{
      height: 2px;
      border: 1px solid #595B60;
      opacity: 0.4;
      margin: 4px auto;
    }
    .disruption-block-select{ 
      .Select-control{
        width: 190px;
        height: 27px;
        color: #595B60;
        font-size: 13px;
        margin: 0 8px 20px 8px;
      }
  }
}
.Select .Select-menu-outer {
    overflow-x: hidden;
}
.Select-menu-outer {
    width: 190px;
    position: absolute;
    padding: 0;
    left: 8px;
    border-bottom: 2px solid #007680 !important;
    border-left: 2px solid #007680 !important;
    border-right: 2px solid #007680 !important;
    border-top: none !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    margin-left: 6px !important;
}
.Select-option{
  padding: 8px 10px 8px 0;
  span{
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  // .Dlabel{
  //   width: 100%;
  //   position: absolute;
  //   right: 2px;
  //   top: -4px;
  // }
  }
}
// .additional-block{
//   .Select-option{
//   span{
//   .Dlabel{
//     right: 12px;
//   }
//   }
// }
// }
// .company-select-block{
//   .Select-option{
//   span{
//   .Dlabel{
//     right: 10px;
//   }
//   }
// }
// }
  .Dicon i {
    width: 16px !important;
    height: 16px !important;
    position: absolute;
    top: 0;
    left: 40px;

}   
}
.filter-shadow{
  box-shadow: 0 2px 20px 0 #007680;
}
